<template>
    <div>
        <template v-if="isMobile || !survey.header_image">
            <h2 class="font-weight-light mb-3">{{ survey.title }}</h2>
			<h3 class="font-weight-light mb-6">{{ survey.subtitle }}</h3>
        </template>
        <v-parallax v-else :src="survey.header_image" height="500px">
			<h1 class="mt-12 font-weight-light mb-7 white--text">{{ survey.title }}</h1>
			<h2 class="mb-12 font-weight-light white--text">{{ survey.subtitle }}</h2>
            <span>
                <v-img v-if="survey.logo" :src="survey.logo" width="auto" height="80" position="right" contain></v-img>
            </span>
		</v-parallax>
    </div>
</template>


<script>
export default {
    name: "SurveyTitle",
    inject: ["isMobile", "survey"],
}
</script>