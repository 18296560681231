<template>
	<span>
		<v-progress-linear v-if="linear && survey.show_completion_rate_bar"
			v-model="rate"
		></v-progress-linear>
		<v-progress-circular v-if="circular"
			v-model="rate"
			color="primary"
			size="37"
		>
			<slot name="content"></slot>
		</v-progress-circular>
	</span>
</template>

<script>
export default {
	name: "CompletionRate",
	props: {
		context: { type: Array },
		data: { type: Object },
		circular: {type: Boolean, default: false },
		disabled: {type: Boolean, default: false },
		linear: {type: Boolean, default: true },

	},
	inject: ["survey"],
	computed: {
		questions() {
			return this.$store.state.questions.data;
		},
		answeredQuestions() {
			const self = this;
			let answeredIds = Object.keys(self.data);
			answeredIds = answeredIds.filter((id) => self.context.includes(id));
			let answered = answeredIds.filter(
				(a) => typeof self.data[a] !== "undefined" && self.data[a] !== ""
			);
			answered = answered.filter((a) => self.countedQuestions.includes(a));
			return answered;
		},
		countedQuestions() {
			const self = this;
			return self.context
				.filter((q) => self.questions[q])
				.filter((q) => {
					let question = self.questions[q];
					return (
						!question.exclude_from_count &&
						question.type !== "html" &&
						!question.always_hidden
					);
				});
		},
		rate() {
			return (
				(this.answeredQuestions.length / this.countedQuestions.length) * 100
			);
		},
	},
};
</script>
