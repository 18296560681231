<template>
	<v-sheet color="transparent" class="pa-5">		
		<render-content v-if="showIntro" :id="survey.introduction"></render-content>
		<v-row>
			<v-col >
				<questions
					:ids="visibleQuestions"
					v-model="data"
					@error="error = true"
				></questions>
				<p v-if="!valid" class="error--text">Please answer all mandatory questions</p>
				<v-btn color="primary" @click="$emit('submit', data)" block text :disabled="!valid">Submit</v-btn>
				<completion-rate
					:id="id"
					:data="data"
					:context="visibleQuestions"
				></completion-rate>
			</v-col>
			<v-col cols="3" v-if="showSidebar && survey.show_sidebar">
				<v-card flat class="rounded-xl">
					<v-card-text>
						<render-content id="surveysidebar" class="mb-5"></render-content>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
		<v-alert color="primary" text v-if="noQuestions">
			No questions found
		</v-alert>
	</v-sheet>
</template>
<style lang="less">
.v-text-field--filled .v-input__slot,
.v-textarea--filled .v-input__slot {
	background-color: white !important;
}
</style>
<script>
import Questions from "@c/survey/render/Questions.vue";
import CompletionRate from "@c/survey/render/CompletionRate.vue";
import RenderContent from "@c/ui/RenderContent.vue";
export default {
	name: "DesktopSurvey",
	data: () => {
		return {
			data: {},
		};
	},
	props: {
		id: {type: String },
		showIntro: {type: Boolean, default: false },
		showSidebar: {type: Boolean, default: false },
		disable: {type: Boolean}
	},
	components: {
		RenderContent,
		Questions,
		CompletionRate,
	},
	computed: {
		visibleQuestions() {
			return this.getVisibleQuestions(this.data);
		},
		valid(){
			const self = this;
			if( self.disable ){
				return false;
			}
			let unanswered = self.visibleQuestions.filter( q => {
				let question = self.questions[q];
				if( question.mandatory ){
					return !self.isAnswered(self.data, q)
				}
			})
			return unanswered.length == 0
		}
	},
	watch: {
		response(value) {
			if (value) {
				this.data = value.data;
			}
		},
	},
	inject: [ "isMobile", "survey", "showIdentity", "noQuestions", "response", "questions", "getVisibleQuestions", "isAnswered" ],
};
</script>
