<template>
	<v-sheet color="transparent" class="mb-12" @click="warn">
		<question
			v-for="id in ids"
			:key="id"
			:id="id"
			v-model="value[id]"
			:data="value"
			:disabled="disabled"
		></question>
	</v-sheet>
</template>

<style lang="less"></style>

<script type="text/javascript">
import Question from "@c/survey/render/Question";

export default {
	name: "Questions",
	props: {
		ids: { type: Array },
		value: { type: Object },
		disabled: { type: Boolean, default: false },
	},
	data: () => {
		return {};
	},
	components: {
		Question,
	},
	computed: {
		questions() {
			return this.$store.state.questions.data;
		},
	},
	watch: {
		ids(nv, ov) {
			if( ov ){
				let now_invisible = ov.filter( o => !nv.includes(o) );
				this.removeUnusedValues(now_invisible)
			}
		}
	},
	methods: {
		removeUnusedValues(keys){
			const self = this;
			keys.forEach( o => {
				let value = self.value;
				value[o] = null;
				self.$emit("input", value );
			})

		},
		warn() {
			if (this.disabled) {
				this.$emit("error");
			}
		},
	},
};
//
</script>
"
