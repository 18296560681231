<template>
		<v-footer color="white" fixed class="pt-0" style="z-index: 1000">
			<v-row class="mb-3 mt-4">
				<v-col>
					<v-btn color="primary" @click="prev()" :disabled="noPrev" icon>
						<v-icon large>mdi-chevron-left-circle</v-icon>
					</v-btn>
					<v-btn
						color="primary"
						@click="next()"
						:disabled="noNext || !valid"
						icon
					>
						<v-icon large>mdi-chevron-right-circle</v-icon>
					</v-btn>
				</v-col>
			</v-row>
			<v-btn
				large
				color="primary"
				v-if="lastQuestion"
				class="mr-2"
				:disabled="!valid"
				@click="$emit('submit')"
				>SUBMIT</v-btn
			>
			<completion-rate
				circular
				:linear="false"
				:data="data"
				:context="context"
			>
				<template v-slot:content>
					<v-icon color="primary">mdi-check</v-icon>
				</template>
			</completion-rate>
		</v-footer>
</template>
<script>
import CompletionRate from "@c/survey/render/CompletionRate.vue";

export default {
	name: "MobileControls",
	props: {
		value: { type: [String, Number] },
		context: { type: Array },
		data: { type: Object },
		missingContactDetails: { type: Boolean },
	},
	inject: ["isMobile"],
	computed: {
		windows() {
			const self = this;
			return self.context.filter((q) => {
				let question = self.$store.state.questions.data[q];
				return !question || !question.always_hidden;
			});
		},
		questions() {
			return this.$store.state.questions.data;
		},
		noPrev() {
			return this.value == this.windows[0];
		},
		noNext() {
			let last = this.windows.length - 1;
			return this.value == this.windows[last];
		},
		lastQuestion() {
			return this.value == this.context[this.context.length - 1];
		},
		valid() {
			const self = this;
			if( self.missingContactDetails ){
				return false;
			}
			let question = self.questions[self.value];
			if (question && question.mandatory) {
				return self.isAnswered(self.value);
			}
			return true;
		},
	},
	methods: {
		isAnswered(q) {
			return (
				this.data[q] &&
				(this.data[q].length || Object.values(this.data[q]).length)
			);
		},
		next() {
			let curr = this.windows.indexOf(this.value);
			this.$emit("input", this.windows[curr + 1]);
		},
		prev() {
			let curr = this.windows.indexOf(this.value);
			this.$emit("input", this.windows[curr - 1]);
		},
	},
	components: {
		CompletionRate,
	},
};
</script>
