<template>
	<v-sheet v-if="survey" color="transparent" id="survey-inner">
		<loading v-if="loading"></loading>
		<survey-title ></survey-title>
		<desktop-survey
			:id="id"
			:show-intro="showIntro"
			:show-sidebar="showSidebar"
			v-if="!isMobile"
			:disable="disable"
			@submit="submit"
		></desktop-survey>
		<mobile-survey
			v-else
			:id="id"
			@submit="submit"
			:disable="disable"
		></mobile-survey>
	</v-sheet>
</template>
<style lang="less"></style>
<script>
import firebase from "firebase";
import SurveyTitle from "@c/survey/render/SurveyTitle.vue";
import DesktopSurvey from "@c/survey/render/DesktopSurvey.vue";
import Loading from "@c/ui/Loading";
import MobileSurvey from "@c/survey/render/MobileSurvey.vue";
export default {
	name: "InnerSurvey",
	props: {
		id: { type: String },
		retrieveResponseId: { type: String },
		showSidebar: {type: Boolean, default: false },
		showIntro: {type: Boolean, default: false },
		disable: { type: Boolean },
	},
	data: () => {
		return {
			error: false,
			loading: false,
		};
	},
	components: {
		SurveyTitle,
		DesktopSurvey,
		MobileSurvey,
		Loading,
	},
	inject: ["isMobile"],
	computed: {
		survey() {
			return this.$store.state.surveys.data[this.id];
		},
		noQuestions() {
			return this.survey && !this.survey.questions.length;
		},
		response() {
			return this.$store.state.surveyResponses.data[this.retrieveResponseId];
		},
		questions() {
			return this.$store.state.questions.data;
		},
	},
	methods: {
		getVisibleQuestions(data) {
			const self = this;
			let ids = self.survey.questions;
			ids = ids.filter((id) => self.questions[id]);
			ids = ids.filter((id) => {
				let question = self.questions[id];
				if (!question.visible_if) {
					return true;
				}
				return self.mwsurveyutils.isVisible(question, data);
			});
			return ids;
		},
		isAnswered(data, q) {
			return data[q] && (data[q].length || Object.values(data[q]).length);
		},
		async newUser(participant) {
			const functions = firebase.app().functions("europe-west1");
			var go = functions.httpsCallable("newUser");
			participant.role = "participant";
			return go(participant).then((result) => {
				return result.data;
			});
		},
		async userId(participant) {
			const self = this;
			if (self.showIdentity) {
				if (participant.id) {
					return participant.id;
				} else {
					return await self.newUser(participant);
				}
			}
			return null;
		},
		submit(data, participant) {
			if (this.disable) {
				return;
			}
			const self = this;
			self.loading = true;
			let response = {
				survey: self.id,
				data,
			};

			self.userId(participant).then((user) => {
				response.participant = user;
				self.$emit("save", response);
				self.$emit("setUser", user);
			});
			// }
		},
	},
	provide() {
		return {
			survey: this.survey,
			showIdentity: this.showIdentity,
			noQuestions: this.noQuestions,
			response: this.response,
			questions: this.questions,
			getVisibleQuestions: this.getVisibleQuestions,
			isAnswered: this.isAnswered,
		};
	},
};
</script>
