<template>
    <v-sheet color="transparent">
        <template>
            <h2 v-if="contentTitle" class="mb-5">{{contentTitle}}</h2>
            <div v-html="contentHtml"></div>
        </template>
    </v-sheet>
</template>
<script>
    export default {
        name: "RenderContent",
        props: {
            id: { type: String }
        },
        computed: {
            contentId(){
                if( this.mwutils.isId(this.id ) ) {
                    return this.id;
                }
                return false;
            },
            content(){
                return this.$store.state.content.data[this.contentId]
            },
            contentTitle(){
                if( !this.content ){
                    return false;
                }
                return this.content.title || false;
            },
            contentHtml(){
                if( !this.content ){
                    return this.mwutils.isId(this.id ) ? "" : this.id;
                }
                return this.content.html
            }
        },
        watch: {
            id: {
                immediate: true, 
                handler(value){
                    if( value ){
                        
                        this.$store.dispatch("content/fetchById", this.contentId)
                    }
                }
            }
        }

    }
</script>